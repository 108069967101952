<template>
    <div
        ref="outerNodeRef"
        class="transition-[height]"
        :class="transitioning ? 'overflow-y-clip' : ''"
    >
        <div ref="innerNodeRef">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es';

let resizeObserver: ResizeObserver | null = null;

const outerNodeRef = ref<HTMLElement | null>(null);
const innerNodeRef = ref<HTMLElement | null>(null);

const transitioning = ref(false);

const recalculateHeight = () => {
    transitioning.value = true;

    nextTick(() => {
        if (outerNodeRef.value && innerNodeRef.value) {
            outerNodeRef.value.style.height = `${innerNodeRef.value.clientHeight}px`;
        }

        setTimeout(() => {
            transitioning.value = false;
        }, 200);
    });
};

const debouncedRecalculateHeight = debounce(recalculateHeight);

onMounted(() => {
    resizeObserver = new ResizeObserver(debouncedRecalculateHeight);

    if (innerNodeRef.value) {
        resizeObserver.observe(innerNodeRef.value);
    }

    recalculateHeight();
});

onBeforeUnmount(() => {
    if (resizeObserver && innerNodeRef.value) {
        resizeObserver.unobserve(innerNodeRef.value);
    }
});

defineExpose({ recalculateHeight });
</script>
